<template>
  <div
    class="player-atc-wrapper"
    @click.self="isPopup && changeIframe('destroyIframe')"
  >
    <div
      class="player-container"
      :class="[
        {
          'is-story': isStory,
          isSquare,
          isPortrait: isPortrait && !isSquare,
          isPopup: isPopup,
        },
        { isLoading: isLoading && isCurrentVideo },
        { isTrimmedPlaying },
        { isHorizontalSwipe },
      ]"
      @mouseover="hideTitleAndShowControlsOnHover()"
      @mouseleave="showTitleAndHideControlsOnLeave()"
      v-visibility-change="visibilityChange"
      :style="isInPreview && isBubble && !isFullScreen ? 'height: auto !important;' : ''"
      ref="playerContainer"
    >
      <div class="controls-video-container" :style="borderRadiusStyle">
        <close-button
          v-if="showCloseButton"
          :video-index="videoIndex"
        ></close-button>
        <!-- TODO would be better to move in iteration section -->
        <player-text
          v-if="showPlayerText"
          :video-index="videoIndex"
          :is-quick-shop="showQuickShop"
        ></player-text>
        <video
          @timeupdate="getCurrentTime($event)"
          @click="togglePlayPause()"
          @error="onError()"
          @ended="onEnded()"
          @pause="onPause()"
          @playing="onPlay()"
          @canplaythrough="onCanplaythrough()"
          ref="videoPlayer"
          :class="['vidjet-video', { isFullScreen }, { hideVideo }]"
          preload="metadata"
          :style="[...videoStyle]"
          :poster="thumbnail"
          playsinline
        ></video>
        <transition name="fade">
          <VideoControls
            v-show="
              showControls && currentTime > 0 && !isTrimmedPlaying && !isLoading
            "
            :isPaused="player && player.paused"
            :isMuted="isMuted"
            :currentTime="currentTime"
            :video-index="videoIndex"
            @pause-video="togglePlayPause"
            @update-player-time="updateCurrentTime"
          />
        </transition>
        <button
          v-show="!isTrimmedPlaying && !isLoading && currentTime > 0"
          class="control-icon mute-control"
          :class="{ 'inline-control-icon': !isFullScreen }"
          @click="toggleMute(!isMuted)"
        >
          <SoundOff v-if="isMuted" class="sound-off-icon" />
          <MuteIcon v-else class="mute-icon" />
        </button>
      </div>
      <PlayPause
        class="play-pause-container"
        v-show="showPlayPause && !isLoading"
        @click.native="togglePlayPause()"
        @touchend.native="togglePlayPause()"
        :is-small="isStory && !isPlayerOpened"
        :showPlayButton="showPlayButton"
        :hasNotClicked="hasNotClicked"
      />
      <div
        v-if="showQuickShop"
        class="quick-shop-wrapper"
        :style="productBackgroundColor"
      >
        <div class="product-info" :style="bottomCornersRadiusStyle">
          <img
            :src="cta.products[0].imageUrl"
            alt="product-img"
            class="quick-shop-product-image"
          />
          <div class="product-name-price" :style="productTextColor">
            <p class="product-name">
              {{
                cta.products[0].name.length > 24
                  ? decodeText(cta.products[0].name.substring(0, 24) + "...")
                  : decodeText(cta.products[0].name)
              }}
            </p>
            <p class="product-price">
              {{ displayCurrency(currency, cta.products[0].variants[0].price) }}
            </p>
          </div>
        </div>
        <div
          class="quick-shop-button"
          :style="[bottomCornersRadiusStyle, ctaColorsStyle]"
          v-tooltip="{
            text: $t('player.productAdded'),
            trigger: 'click',
          }"
          @click.stop="openAtcForm"
        >
          <div v-if="isQuickShopLoading" class="quick-shop-clicked">
            <div class="lds-ring" :style="ctaColorsStyle">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div v-else class="quick-shop-button">
            <ShoppingBag class="shopping-bag" :style="ctaColorsStyle" />
            <p :style="ctaColorsStyle">{{ quickShopCtaAction }}</p>
          </div>
        </div>
      </div>
      <!-- this should be moved to player interface -->
      <NavigationArrows
        v-if="showNavigationArrows"
        class="navigation-arrows-container"
        :video-index="videoIndex"
      />
      <div class="player-interface">
        <PlayerInterface
          v-if="showPlayerInterface"
          :showTitle="!showControls && !isMultiple && !isFullScreen"
          :showControls="showControls || player.paused"
          :video-index="videoIndex"
          :is-current-video="isCurrentVideo"
          :is-cta-within-range="isCtaWithinRange"
          @notify-preview="$emit('notify-preview')"
          @pause-video="player.pause()"
        />
      </div>
      <AtcForm
        v-if="showAtcForm"
        v-click-outside="closeAtcForm"
        :video-index="videoIndex"
        :product="cta.products[0]"
        :is-quick-shop="true"
        class="atc-form-on-thumbnail"
        @close="closeAtcForm"
        @added-to-cart="ctaClicked"
        @buy-now-clicked="ctaClicked"
      ></AtcForm>
      <SpinnerLoader
        v-show="isLoading && isCurrentVideo && isPlayerOpened"
        :isBigSpinner="false"
        background="transparent"
      />
    </div>
    <div v-if="showAtcFullScreen && isCtaWithinRange" class="atc-form">
      <atc-multiple-products
        :video-index="videoIndex"
        style="height: calc(var(--vh, 1vh) * 100)"
      ></atc-multiple-products>
    </div>
  </div>
</template>

<script>
import enums from "@/enums.js";
import api from "@/api/api.js";

import { changeIframe } from "@/utils/utils";

const { FormatType, CtaType } = enums;

import { mapActions, mapGetters, mapMutations } from "vuex";

import { displayCurrency } from "@/utils/utils";

import { decodeText } from "@/utils/utils.js";

export default {
  components: {
    PlayPause: () =>
      import("../components/player-components/PlayPauseControls.vue"),
    PlayerInterface: () => import("../components/PlayerInterface.vue"),
    NavigationArrows: () =>
      import("@/components/additional-components/NavigationArrows.vue"),
    AtcMultipleProducts: () => import("./add-to-cart/AtcMultipleProducts.vue"),
    SpinnerLoader: () => import("@/components/shared/SpinnerLoader.vue"),
    PlayerText: () => import("./player-components/PlayerText.vue"),
    CloseButton: () => import("./player-components/CloseButton.vue"),
    VideoControls: () => import("./player-components/VideoControls.vue"),
    MuteIcon: () => import("@/assets/mute-icon.svg"),
    SoundOff: () => import("@/assets/sound-off.svg"),
    ShoppingBag: () => import("@/assets/shopping-bag.svg"),
    AtcForm: () => import("./add-to-cart/AtcForm.vue"),
  },
  data() {
    return {
      player: null,
      // video player data properties
      hasNotClicked: true,
      showPlayButton: true,
      viewDurationSent: 0,
      showControls: true,
      watchId: null,
      timerId: null,
      touchEvent: null,
      // enums
      FormatType,
      CtaType,
      startY: 0,
      isLoading: false,
      detachTooltip: null,
      revertProgressBar: null,
      // had to add this property since player.currentSrc is not reliable
      // check if w/o vjs it is more reliable
      isTrimmedPlaying: false,
      changeIframe,
      observer: null,

      currentTime: 0,
      showAtcForm: false,
      additionalData: {},
      displayCurrency,
      isQuickShopLoading: false,
      decodeText,
    };
  },

  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
    clickedIndex: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    async isCurrentVideo(newValue) {
      if (!this.isPlayerOpened) {
        return;
      }
      // for preview when selecting one video
      if (
        newValue &&
        (this.player?.src === this.placeholderUrl || this.player?.src === "")
      ) {
        this.isLoading = true;
        this.player.muted = this.isMuted;
        this.showPlayButton = false;
        this.player.muted = this.isMuted;

        await this.setSources();
      }
      if (newValue && !this.isCarouselInline) {
        // this.showPlayButton = false;
        this.player.muted = this.isMuted;
        this.player.currentTime = 0;
        this.player.play();
        // for format multiple keep behaviour of the player as if user hasn't clicked
      } else if (!newValue && this.isPlayerOpened) {
        this.sendViewDuration();
        this.player.pause();
        !this.isCarousel && (this.player.currentTime = 0.1);
      }
    },
    // TODO: is this needed?
    isMuted() {
      this.hasNotClicked = false;
      this.player.muted = this.isMuted;
    },

    // all these watcher could be deleted if we were to simply reset player
    // for preview
    thumbnail(oldValue, newValue) {
      if (!this.isInPreview) {
        return;
      }
      if (newValue !== oldValue) {
        if (!this.thumbnail && this.player.poster_) {
          this.player.pause();
          this.player.currentTime(0.1);
        } else if ((this.isEmbed || this.isCarouselInline) && this.thumbnail) {
          this.showThumbnail();
        } else if (this.isCarousel && this.thumbnail && this.isFullScreen) {
          this.togglePlayer(false);
          this.showThumbnail();
        }
      }
    },

    // for preview
    textOptionsPreview() {
      if (this.isInPreview) {
        this.player.pause();
        this.player.currentTime(0);
        this.player.hasStarted(false);
        this.hasNotClicked = true;
        this.player.posterImage.show();
      }
    },

    // added a prop on step 2 otherwise player get reset each time cta is modified
    "video.url": {
      async handler(oldValue, newValue) {
        const haveUrlsChanged = oldValue !== newValue;
        if (this.player) {
          if (
            this.player.src !== this.placeholderUrl &&
            haveUrlsChanged &&
            this.isInPreview
          ) {
            this.setSources();

            this.isCurrentVideo && this.vidjetPlay();
          }
        } else if (haveUrlsChanged && this.isTrimmedVideoOnStart) {
          this.playTrimmedVideo();
        }
      },
      deep: true,
    },

    async isTrimmedVideoOnStart(newValue) {
      if (newValue) {
        this.playTrimmedVideo();
      } else if (!newValue) {
        this.showThumbnail();
      }
      this.isTrimmedPlaying = newValue;
    },

    // for preview
    isMobile() {
      if (this.isCurrentVideo) {
        this.vidjetPlay();
      }
    },
    stopAllVideos(newVal) {
      if (newVal) {
        this.player.pause();
        this.player.currentTime = 0;
        if (this.observer) {
          this.observer.disconnect();
          this.observer = null;
        }
      }
    },
  },

  async mounted() {
    this.player = this.$refs.videoPlayer;

    if (
      (this.isFullScreen && this.isPlayerOpened) ||
      (this.isBubble && (this.hasPlayerLoaded || !this.isTrimmedVideoOnStart))
    ) {
      await this.setSources();
    } else if (this.isTrimmedVideoOnStart) {
      this.isLoading = false;
      this.bindViewportDetection();
    } else {
      this.isLoading = false;
      this.showControls = false;
      this.player.poster = this.thumbnail;
    }
    this.setHasPlayerLoaded(true);

    document.addEventListener("keydown", this.handleKeyDown);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);

    if (this.player) {
      this.sendViewDuration();
    }
    if (this.detachTooltip) {
      this.detachTooltip();
    }
  },

  computed: {
    ...mapGetters("playerCampaign", [
      "campaign",
      "format",
      "isEmbed",
      "isBubble",
      "isCarousel",
      "isPopup",
      "isFullScreen",
      "isCarouselFullScreen",
      "isStory",
      "isHorizontalSwipe",
      "isTrimmedVideoOnStart",
      "stopAllVideos",
    ]),
    ...mapGetters("videos", [
      "videos",
      "isMultiple",
      "isPortrait",
      "isSquare",
      "isFirstVideo",
      "isLastVideo",
      "getIsCurrentVideoByIndex",
      "getVideoByIndex",
      "textOptionsPreview",
      "hasYoutubeThumbnail",
    ]),
    ...mapGetters("player", [
      "isMobile",
      "isInPreview",
      "isIos",
      "isPlayerOpened",
      "hasPlayerLoaded",
      "isMuted",
    ]),
    ...mapGetters("playerSite", ["site", "currency", "integration"]),
    ...mapGetters("videoJS", ["placeholderUrl"]),

    video() {
      if (this.hasYoutubeThumbnail && this.videoIndex === this.videos.length) {
        return {
          thumbnail: "https://media.vidjet.io/youtube+logo.jpg",
          isIxtemSpecialThumbnail: true,
        };
      }

      return this.getVideoByIndex(this.videoIndex);
    },

    thumbnail() {
      return this.video.thumbnail;
    },

    cta() {
      return this.video.cta ?? {};
    },

    textOptions() {
      return this.video.textOptions ?? {};
    },

    isCurrentVideo() {
      // when thumbnail are animated all videos in carousel have to play
      if (this.isCarouselInline) {
        return (
          this.getIsCurrentVideoByIndex(this.videoIndex) &&
          this.clickedIndex === this.videoIndex
        );
      } else {
        return this.getIsCurrentVideoByIndex(this.videoIndex);
      }
    },

    // controls
    showPlayPause() {
      const hideStoryPlay = this.hideStoryPlay;
      const isOpened = this.isPlayerOpened;

      if (this.video.isIxtemSpecialThumbnail) {
        return false;
      }
      // for one client that has specific play btn
      if (this.hidePlayThumb && !isOpened) {
        return true;
      }
      if (this.isBubble && this.format.openByDefault && this.hasNotClicked) {
        return true;
      }
      if (this.isStory) {
        if (!hideStoryPlay && !isOpened) {
          return true;
        }
        if (hideStoryPlay) return false;
      }

      if (!this.hideThumbnailPlay && this.isFullScreen && !isOpened) {
        return true;
      }
      if (!this.hideThumbnailPlay && this.isFullScreen && isOpened) {
        return false;
      }
      if (!this.hideThumbnailPlay && this.isEmbed && this.hasNotClicked) {
        return true;
      }
      if (
        !this.hideThumbnailPlay &&
        !this.isFullScreen &&
        this.isCurrentVideo
      ) {
        return false;
      }
      return !this.hideThumbnailPlay;
    },
    showPlayerInterface() {
      return this.currentTime > 0.1 && !this.isTrimmedPlaying;
    },

    showCloseButton() {
      if (
        this.isCarouselInline ||
        this.isEmbed ||
        (this.isCarousel && !this.isPlayerOpened)
      )
        return false;
      return true;
    },

    isCarouselInline() {
      return this.isCarousel && !this.isFullScreen;
    },

    hideStoryPlay() {
      return !this.isPlayerOpened && this.format?.hideStoryPlay;
    },
    hideVideo() {
      return this.player && this.isCarousel && this.player.paused;
    },

    showPlayerText() {
      if (this.isCarousel && this.isFullScreen && this.isPlayerOpened) {
        return false;
      }
      return (
        (this.isEmbed || (this.isCarousel && !this.isStory)) &&
        !this.showPlayerInterface &&
        !this.isLoading &&
        Object.keys(this.textOptions).length > 0
      );
    },

    showAtcFullScreen() {
      return (
        this.cta.ctaType === CtaType.product &&
        this.isFullScreen &&
        !this.isMobile &&
        !this.isHorizontalSwipe &&
        this.cta.products &&
        this.cta.products[0] &&
        this.cta.products[0]._id !== "" &&
        this.isPlayerOpened
      );
    },
    ctaColorsStyle() {
      return {
        backgroundColor:
          this.format?.quickShop?.buttonBackgroundColor || "black",
        color: this.format?.quickShop?.buttonTextColor || "white",
        fill: this.format?.quickShop?.buttonTextColor || "white",
      };
    },

    canSendViewDuration() {
      if (this.isFullScreen) {
        return this.player.currentTime > 3 && this.isPlayerOpened;
      } else {
        return !this.hasNotClicked && this.isPlayerOpened;
      }
    },

    //We want to display the navigation on all multiple formats except the carousel inline.
    showNavigationArrows() {
      return (
        !this.hideArrows &&
        this.isMultiple &&
        this.isPlayerOpened &&
        !(this.isCarousel && !this.isFullScreen)
      );
    },

    hideArrows() {
      return this.format.hideFeedArrow ?? false;
    },

    hidePlayThumb() {
      return this.format?.hidePlayThumb ?? false;
    },
    isCtaWithinRange() {
      if (!Object.keys(this.cta?.timeframe ?? {}).length === 0) {
        return true;
      } else if (
        this.cta?.timeframe?.fromSec &&
        this.currentTime < this.cta?.timeframe.fromSec
      ) {
        return false;
      } else if (
        this.cta?.timeframe?.toSec &&
        this.currentTime > this.cta?.timeframe.toSec
      ) {
        return false;
      }
      return true;
    },
    videoStyle() {
      const styles = [];

      if (this.isCarousel && !(this.isFullScreen && this.isPlayerOpened)) {
        styles.push({ "object-fit": "cover" });
      }
      if (!this.isFullScreen && this.isBubble && !this.isInPreview) {
        // on multiple we force videos to be 16:9
        const aspectRatio = this.isMultiple
          ? 1.77777
          : this.video.height / this.video.width;
        styles.push({ height: `calc(var(--vw, 1vw) * 100 * ${aspectRatio})` });
      }

      return styles;
    },
    borderRadiusStyle() {
      if (this.isFullScreen && this.isPlayerOpened && !this.isHorizontalSwipe) {
        return {};
      } else if (Number.isInteger(this.format.cornerShape)) {
        let borderRadius = this.format.cornerShape.toString() + "px";
        return { borderRadius: borderRadius };
      } else {
        return { borderRadius: "10px" };
      }
    },
    hideThumbnailPlay() {
      return this.format.hideThumbnailPlay;
    },
    bottomCornersRadiusStyle() {
      if (Number.isInteger(this.format.cornerShape)) {
        let borderRadiusValue = this.format.cornerShape.toString() + "px";
        return {
          borderBottomLeftRadius: borderRadiusValue,
          borderBottomRightRadius: borderRadiusValue,
        };
      } else {
        return {
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        };
      }
    },
    videoHasProducts() {
      return (
        this.cta.ctaType === CtaType.product && this.cta.products.length >= 1
      );
    },
    showQuickShop() {
      if (this.isCarousel && this.isFullScreen && this.isPlayerOpened) {
        return false;
      }

      return (
        this.format?.quickShop?.isActive === true &&
        this.isCarousel &&
        this.videoHasProducts &&
        !this.isLoading &&
        !this.showPlayerInterface &&
        !this.showAtcForm
      );
    },
    productBackgroundColor() {
      const backgroundColor =
        this.format?.quickShop?.productBackgroundColor || "(rgb(0, 0, 0, 0)";
      return `background: linear-gradient(180deg, rgba(255, 0, 0, 0.00) 0%, ${backgroundColor} 100%)`;
    },
    productTextColor() {
      return { color: this.format?.quickShop?.productTextColor || "white" };
    },
    quickShopCtaAction() {
      if (this.format?.quickShop?.ctaAction === "buy_now") {
        return this.$t("player.atcFormAddButton.buyNow");
      } else return this.$t("player.atcFormAddButton.addToCart");
    },
  },

  methods: {
    ...mapMutations({
      togglePlayer: "player/TOGGLE_PLAYER",
      toggleMute: "player/TOGGLE_MUTE",
      setCurrentIndex: "videos/SET_CURRENT_INDEX",
      toggleAtc: "player/TOGGLE_ATC",
      setHasPlayerLoaded: "player/SET_HAS_PLAYER_LOADED",
      setBuyNowProduct: "analytics/SET_BUY_NOW_PRODUCT",
    }),
    ...mapActions({
      sendEvent: "analytics/sendEvent",
    }),
    async initPlayer() {
      this.watchId = this.$uuid.v1();
      if (this.player) {
        // // on initialisation we mute on mobile so it autoplay, ios is very reluctant to autoplaying videos
        if (this.isCurrentVideo) {
          this.showPlayButton = false;

          //If it's null it means it was never set. if it has a value, we will keep the muted state the the user chooses
          if (this.isMuted === null) {
            this.player.muted = this.isIos;
            this.toggleMute(this.isIos);

            //Toggle sound on for some clients
            this.forceSoundOn();
          } else {
            this.player.muted = this.isMuted;
            this.toggleMute(this.isMuted);
          }

          this.player.loop = this.format.autoLoop;
          this.isTrimmedPlaying = false;
          this.hasNotClicked = false;
          this.vidjetPlay();
          if (this.isPopup) {
            this.togglePlayer(true);
            this.hasNotClicked = false;
          }
        }
      }
    },

    setSources() {
      const deviceVideoStreamingUrl =
        this.isMobile && this.video.mobileStreamingUrl
          ? this.video.mobileStreamingUrl
          : this.video.streamingUrl;

      if (this.isCurrentVideo) {
        if (this.video.videoDuration > 30) {
          if (this.player.canPlayType("application/vnd.apple.mpegurl")) {
            this.player.src = deviceVideoStreamingUrl;
            this.initPlayer();
          } else {
            import("hls.js")
              .then((module) => {
                const Hls = module.default;
                if (Hls.isSupported()) {
                  const hls = new Hls({
                    // Buffer settings
                    maxBufferLength: 3, // Max buffer length in seconds
                    maxMaxBufferLength: 10, // Max buffer length cap in seconds
                    maxBufferSize: 60 * 1000 * 1000, // Max buffer size in bytes
                    maxBufferHole: 0.5, // Max buffer hole in seconds

                    // Adaptive Bitrate (ABR) settings
                    abrEwmaFastLive: 3, // Fast ABR EWMA live
                    abrEwmaSlowLive: 9, // Slow ABR EWMA live
                    abrEwmaFastVoD: 3, // Fast ABR EWMA VoD
                    abrEwmaSlowVoD: 9, // Slow ABR EWMA VoD
                    abrEwmaDefaultEstimate: 500000, // Default ABR EWMA estimate in bps
                    abrBandWidthFactor: 0.95, // ABR bandwidth factor
                    abrBandWidthUpFactor: 0.7, // ABR bandwidth up factor

                    // Low latency mode
                    lowLatencyMode: true, // Enable low latency mode
                    liveSyncDuration: 5, // Live sync duration in seconds
                    liveMaxLatencyDuration: 10, // Max live latency duration in seconds

                    // Error handling and retries
                    manifestLoadingMaxRetry: 4, // Max manifest loading retries
                    manifestLoadingRetryDelay: 1000, // Manifest loading retry delay in ms
                    levelLoadingMaxRetry: 4, // Max level loading retries
                    levelLoadingRetryDelay: 1000, // Level loading retry delay in ms
                    fragLoadingMaxRetry: 6, // Max fragment loading retries
                    fragLoadingRetryDelay: 1000, // Fragment loading retry delay in ms

                    // Initial configuration
                    startLevel: undefined, // Start level for playback
                    startFragPrefetch: true, // Enable fragment prefetching
                    initialLiveManifestSize: 1, // Initial live manifest size
                    autoStartLoad: true, // Automatically start loading after initialization
                  });

                  hls.loadSource(deviceVideoStreamingUrl);
                  hls.attachMedia(this.player);
                  this.initPlayer();
                } else {
                  this.player.src = this.video.url;
                  this.initPlayer();
                }
              })
              .catch(() => {
                this.player.src = this.video.url;
                this.initPlayer();
              });
          }
        } else {
          this.player.src = this.video.url;
          this.initPlayer();
        }
      } else {
        this.player.src = this.placeholderUrl;
      }
    },

    // avoid error play was interrupted when we try to play the video before it is loaded
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    vidjetPlay(forcePlay) {
      if (
        forcePlay ||
        (this.isCurrentVideo && this.player.paused && this.isPlayerOpened)
      ) {
        // Function to add timeout to the promise
        const addTimeoutToPromise = (promise, timeoutDuration) => {
          return Promise.race([
            promise,
            new Promise((_, reject) =>
              setTimeout(
                () => reject(new Error("Promise timed out")),
                timeoutDuration
              )
            ),
          ]);
        };

        const playPromise = this.player.play();
        const timeoutDuration = 1000;
        //We add timeout to the promise because something it's getting stuck in pending without resolving
        const playPromiseWithTimeout = addTimeoutToPromise(
          playPromise,
          timeoutDuration
        );

        if (playPromiseWithTimeout !== undefined) {
          playPromiseWithTimeout
            .then(() => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch((error) => {
              if (error.name === "NotAllowedError") {
                // Handle the case when autoplay is blocked
                this.isLoading = false;
                this.showPlayButton = true;
                this.showPosterImage();
              } else {
                // it happens the play promise get stuck, so we force the play again
                this.player.play();
              }
            });
        }
      } else if (!this.isCurrentVideo && !this.isTrimmedPlaying) {
        this.player.pause();
      }
    },

    sendViewDurationOnLeave() {
      window.addEventListener("beforeunload", () => {
        this.sendViewDuration();
      });
    },

    showPosterImage() {
      this.player.pause();
      this.player.currentTime = 0;
      this.player.poster = this.video.thumbnail;
      this.hasNotClicked = true;
    },

    visibilityChange(e, hidden) {
      if (!this.player) return;
      if (hidden && this.isMobile) {
        this.player.pause();
      } else if (hidden) {
        this.sendViewDuration();
      }
    },

    sendViewDuration() {
      if (
        this.player?.currentTime > this.viewDurationSent + 0.5 &&
        this.canSendViewDuration &&
        !this.isInPreview
      ) {
        this.sendEvent(
          {
            type: "viewDuration",
            watchId: this.watchId,
            videoViewTime: this.player.currentTime,
            videoTotalDuration: this.video.videoDuration,
            percentageSeen:
              (this.player.currentTime / this.video.videoDuration) * 100,
          },
          this.isEmbed
        );
        this.viewDurationSent = this.player.currentTime;
      }
    },

    togglePlayPause() {
      //  Stop other trimmed videos when one is playing
      if (!this.isPlayerOpened) {
        return;
      }
      if (this.isCarouselInline && this.isTrimmedPlaying) {
        this.$store.dispatch("playerCampaign/stopAllVideos", true);
        this.setSources();
      }
      // we don't want to play when user is paginating
      if (
        (this.isCarouselInline && !this.isCurrentVideo) ||
        (this.isCarouselFullScreen && !this.isPlayerOpened)
      ) {
        return;
      } else if (this.isTrimmedPlaying) {
        this.isTrimmedPlaying = false;
        this.setSources();
      } else {
        if (this.player.src === "") this.setSources();

        this.player.paused ? this.player.play() : this.player.pause();
      }
    },

    unmuteVideo() {
      this.hasNotClicked = false;
      this.player.muted(false);
      this.isMultiple && this.toggleMute(false);
    },

    // so controls hide directly when leaving the player
    hideTitleAndShowControlsOnHover() {
      this.showControls = true;
    },

    showTitleAndHideControlsOnLeave() {
      if (!this.player.paused) {
        this.showControls = false;
      }
    },

    showThumbnail() {
      this.player.pause();
      this.player.currentTime = 0;
      this.currentTime = 0;
      this.player.poster = this.thumbnail;
      this.player.src = "";
    },

    // for embed
    bindViewportDetection() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.9,
      };
      let video = this.$refs.playerContainer;
      let hasDisplayEventBeenSent = false;
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.playTrimmedVideo();
            this.observer.unobserve(entry.target);
          }
          if (!hasDisplayEventBeenSent && !this.isInPreview) {
            this.isCurrentVideo &&
              this.sendEvent({
                type: "display",
              });
            hasDisplayEventBeenSent = true;
          }
        }),
          options;
      });
      this.observer.observe(video);
    },
    //  start playing video when the video is in the view port
    playTrimmedVideo(retry = false) {
      // since every videos don't have a trimmed url We have to resort to this
      this.isTrimmedPlaying = true;
      this.showControls = false;
      this.player.muted = true;
      this.player.loop = true;

      if (!retry) this.player.src = this.video.trimmedUrl || this.video.url;

      if (this.isIos) {
        this.removeAudioFromTrimmedVideo(this.player);
      }

      this.player.play().catch(async (err) => {
        console.log(err);

        //If the trimmed video cannot play, make sure to change source now and try again
        this.player.src = this.video.url;
        if (!retry) return this.playTrimmedVideo(true);

        this.player.pause();
        this.player.currentTime = 0;
        this.player.poster = this.video.thumbnail;
        this.showPlayButton = true;
        this.player.src = "";
      });

      if (!this.video.trimmedUrl) {
        this.player.addEventListener("timeupdate", this.loopFourSeconds);
      }
    },

    loopFourSeconds() {
      if (this.player.currentTime >= 4) {
        this.player.currentTime = 0; // Looping back to the start
      }
      if (!this.isTrimmedPlaying) {
        this.initPlayer();
        this.player.removeEventListener("timeupdate", this.loopFourSeconds);
      }
    },

    // otherwise safari blocks the autoplay even with the muted attribute
    removeAudioFromTrimmedVideo(player) {
      const mediaStream = player.srcObject;
      // Check if a MediaStream exists
      if (mediaStream) {
        // Get audio tracks from the MediaStream
        const audioTracks = mediaStream.getAudioTracks();
        // Remove each audio track
        audioTracks.forEach((track) => {
          track.stop(); // Stop the track
          mediaStream.removeTrack(track); // Remove the track from the stream
        });
        // Reassign the modified stream to the video element
        player.srcObject = mediaStream;
      }
    },

    getCurrentTime() {
      this.currentTime = this.player.currentTime;
    },

    onError() {
      if (!this.isInPreview) {
        const dataToSend = {
          formatId: this.$route.params.siteId,
          browser: window.navigator.userAgent,
          deviceType: this.$route.query.device,
          date: this.$route.query.date,
        };
        api.sendError(dataToSend);
        this.handleErrorBasedOnSrc();
      }
    },

    // Determine the action based on source type
    handleErrorBasedOnSrc() {
      if (this.player.src.includes("m3u8")) {
        this.player.src = this.video.url;
      }
      // else {
      //   changeIframe("destroyIframe");
      // }
    },

    onEnded() {
      this.sendViewDuration();
      // replace with this.player.loop = true
      if (this.format.autoLoop && !this.format.autoScroll) {
        this.player.currentTime = 0.1;
        this.vidjetPlay();
      }
      if (this.format.autoScroll) {
        this.setCurrentIndex(this.videoIndex + 1);
      }
    },

    onPause() {
      this.sendViewDuration();
      this.showPlayButton = true;
      this.showControls = true;
    },

    onPlay() {
      if (!this.isInPreview) {
        this.sendViewDurationOnLeave();
      }
      if (!this.isCurrentVideo && !this.isTrimmedPlaying) {
        this.player.pause();
      }
      // we hide controls for videos that are not playing on start
      if (!this.isTrimmedPlaying) {
        this.showControls = true;
      }
      if (!this.player.paused) {
        setTimeout(() => (this.showControls = false), 2000);
      }
      this.showPlayButton = this.isTrimmedPlaying;
    },

    onCanplaythrough() {
      if (this.isCurrentVideo) {
        this.isLoading = false;
      }
    },

    updateCurrentTime(payload) {
      this.showControls = true;
      this.player.currentTime = payload;
    },

    handleKeyDown(event) {
      if (
        this.isFullScreen &&
        !this.isInPreview &&
        (event.key === " " || event.key === "Spacebar")
      ) {
        this.togglePlayPause();
        event.preventDefault();
      }
      if (
        event.key === "ArrowDown" &&
        !this.isLastVideo &&
        this.isCurrentVideo
      ) {
        this.setCurrentIndex(this.videoIndex + 1);
      }
      if (
        event.key === "ArrowUp" &&
        !this.isFirstVideo &&
        this.isCurrentVideo
      ) {
        this.setCurrentIndex(this.videoIndex - 1);
      }
    },
    forceSoundOn() {
      const sitesToUnmute = [
        "0854fe18-fab3-41e1-bdaf-a757e9e66b5a",
        "f1eea2b0-b38c-4ba2-ac13-b7746ab3a9d3",
        "43430b1e-da9c-4b58-beac-ac0036211f01",
        "8ecc8e49-14bc-40ab-bf11-dde602c64c67",
        "962da816-597b-4c10-8ed5-bb51c9f777a0",
        "25b1525b-9b2e-4251-9f8a-74290374ecb9",
        "94a57fdb-4b07-4dec-9556-d6421a191ee7",
        "52cead80-e7ef-4d54-8e4b-51ba434efc26",
        "35448a7b-3dbc-4831-897d-ac0a728aa942",
        "23e05429-02ac-418a-a6b2-ed2610730d49",
      ];

      if (sitesToUnmute.includes(this.campaign.siteId)) {
        this.player.muted = false;
        this.toggleMute(false);
      }
    },
    closeAtcForm() {
      this.showAtcForm = false;
    },
    ctaClicked() {
      setTimeout(() => {
        this.closeAtcForm();
      }, 2000);
    },
    openAtcForm() {
      if (this.cta.products[0].variants.length > 1) {
        this.showAtcForm = true;
      } else {
        this.addToCart(this.format.quickShop.ctaAction);
      }
    },

    addToCart(ctaAction) {
      this.isQuickShopLoading = true;
      const dataToSend = {
        messageOrigin: "vidjet",
        origin: "addToCart",
        properties: {
          productId: this.cta.products[0]._id,
          variantId: this.cta.products[0]?.variants[0].id,
        },
      };

      if (ctaAction === "buy_now") {
        dataToSend.properties.isBuyNow = true;
      }

      window.parent.postMessage(dataToSend, "*");

      ctaAction === "buy_now"
        ? this.buyNowClicked({
            variantId: this.cta.products[0]?.variants[0].id,
            vidjetProductId: this.cta.products[0]._id,
            quantity: 1,
          })
        : this.sendEvent({
            type: "addToCartBtnClick",
            productId: this.cta.products[0]._id,
          });
      setTimeout(() => {
        this.isQuickShopLoading = false;
      }, 2000);
    },
    buyNowClicked(payload) {
      if (!this.isInPreview) {
        this.sendEvent({
          type: "buyNowClicked",
          productId: payload.vidjetProductId,
        });
      }

      //We only want to get the cart data before creating the checkout through API for Shopify
      if (this.integration === "shopify") {
        const dataToSend = {
          messageOrigin: "vidjet",
          origin: "getCartItems",
        };

        delete payload.vidjetProductId;
        this.setBuyNowProduct(payload);
        window.parent.postMessage(dataToSend, "*");
      }
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

<style scoped lang="scss">
.player-container {
  position: relative;
  margin: auto;
  z-index: 5;

  video {
    height: inherit;
    width: 100%;
    box-shadow: 2px 5px 2px rgba(0, 0, 0, 0.2);
    background: black;
    &:hover ~ .custom-controls .seekbar {
      height: 10px;
      transition: all ease 0.25s;
    }
  }
}
.isCarousel .player-container .vidjet-video {
  height: 100%;
}

.isFullScreen .player-container,
.isMultiple .player-container {
  .vidjet-video {
    box-shadow: none;
    background: black !important;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
  }
}

.hideVideo .vidjet-video {
  display: none;
}
.isMultiple .player-container .vidjet-video {
  height: 100%;
}

.isPortrait .vidjet-video .vjs-fullscreen-control {
  right: 16px;
  left: unset;
}

.main-wrapper.isFullScreen.isHorizontalSwipe .player-container {
  // for popup
  height: calc(var(--vh, 1vh) * 75);
  max-width: calc(var(--vh, 1vh) * (75 * 0.5625));
}
// transform scale to avoid white border created by blur
.player-container.isLoading {
  background: black;
  border-radius: 10px;
  .vidjet-video {
    width: 98%;
    filter: blur(3px);
    margin: 0 auto;
  }
}

.controls-video-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.player-atc-wrapper {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.isPopup .player-atc-wrapper,
.isFullScreen.isHorizontalSwipe .player-atc-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  margin: 0;
}

.player-container {
  position: relative;
  height: fit-content;
  transition: opacity 0.5s;
  margin: 0 auto;
  width: 100%;
}

.main-wrapper.isMultiple.isBubble,
.isFullScreen .player-container {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.player-atc-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  height: 100%;
  transition: width 0.5s ease-in-out;
  background: "black";
}

.isPopup .player-atc-wrapper {
  place-items: center;
  background: transparent;
}

.isCarousel .player-atc-wrapper {
  place-items: center;
}

.isCarousel .player-container {
  height: 100%;
  // no need for blur on carousel
  &.isLoading .vidjet-video {
    transform: none;
  }
}

.isCarousel .vidjet-carousel-items.is-story .player-container {
  height: 80px;
}

.isBubble.isMultiple {
  .player-atc-wrapper {
    height: 100%;
    width: 100%;
    margin: 0;
  }
}
.isBubble {
  .player-atc-wrapper {
    margin: 0;
    height: auto;
    width: auto;
  }
}

.atc-form {
  width: 350px;
  overflow: auto;
  background: white;
}

.atc-form::-webkit-scrollbar {
  width: 0;
}
.atc-form::-webkit-scrollbar-track {
  background-color: transparent;
}
.atc-form::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.navigation-arrows-container {
  position: absolute;
  z-index: $index-icon-position;
  top: 50%;
  right: 16px;
  transform: translate(0%, -50%);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.player-interface {
  height: 100%;
  width: 100%;
  bottom: 0px;
}

// popup sizing
.isPopup {
  .player-container {
    max-width: 45%;
    width: fit-content;
    height: fit-content;

    @include media(">tablet", "<=desktop") {
      max-width: 60%;
    }

    @include media(">phone", "<=tablet") {
      max-width: 90%;
    }

    @include media("<=phone") {
      max-width: 90%;
    }
  }

  .player-container.isPortrait {
    max-width: 20%;

    &.isMobile {
      max-width: 60%;
      max-height: 500px;
    }

    @include media(">tablet", "<=desktop") {
      max-width: 35%;
    }

    @include media(">phone", "<=tablet") {
      max-width: 50%;
      min-width: 250px;
    }

    @include media("<=phone") {
      max-width: 70%;
    }
  }

  .player-container.isSquare {
    max-width: 30%;

    &.isMobile {
      max-width: 70%;
      max-height: 500px;
    }

    @include media(">tablet", "<=desktop") {
      max-width: 45%;
    }

    @include media(">phone", "<=tablet") {
      max-width: 90%;
      min-width: 250px;
    }

    @include media("<=phone") {
      max-width: 90%;
    }
  }
}
.control-icon {
  position: absolute;
  bottom: 105px;
  right: 16px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(60, 60, 60, 0.6);
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1100px) {
    .control-icon:hover {
      transform: scale(1.1);
    }
  }
  .mute-icon {
    height: 20px;
    width: 20px;
  }
  .sound-off-icon {
    height: 20px;
    width: 20px;
  }
}

.inline-control-icon {
  bottom: 25px;
  right: 8px;
}

.isFullScreen:not(.isHorizontalSwipe) .control-icon {
  height: 40px;
  width: 40px;
  svg {
    transform: scale(1.2);
  }
}

.quick-shop-wrapper {
  z-index: 100;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 0 0 10px 10px;
}

.quick-shop-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 36px;
  p {
    font-size: 16px;
  }
}

.shopping-bag {
  fill: white;
  transform: scale(0.9);
}

.product-info {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding-left: 16px;
  padding-top: 24px;
  cursor: default;
}

.product-name-price {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 60%;
  color: white;
}

.product-name {
  font-weight: 600;
  font-size: 16px;
  height: 32px;
  overflow: hidden;
}

.product-price {
  font-size: 14px;
  font-weight: 400;
}

.quick-shop-product-image {
  width: 60px;
  height: 60px;
  border-radius: 2px;
}

.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
  right: 0;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
